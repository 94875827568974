<!-- 考试大类 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import UserHeader from "../user-header.vue";
  import YzSearch from "@/components/form/yzSearch.vue";
  import ImgHandle from "@/components/user/register_user/use/img-handle.vue";
  import {
    userPhotoPage
  } from "@/api/admin/user.js";
  import {
    deleteUserPhoto,
    handleUserPhone,
    userPhotoHandleBatch
  } from "@/api/userInfo_user";
  import HandleStatus from "@/components/table/handleStatus";
  import yzModalSfzImg from "../examinee/yzModalSfzImg";

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      UserHeader,
      YzSearch,
      ImgHandle,
      HandleStatus,
      yzModalSfzImg,
    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin",
          },
          {
            text: "用户管理",
            active: true,
          },
        ],
        checked: true,
        // defaultvalue: 3,
        // currentpage: 3,
        tabList: [],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 1,
        },
        showPromission: false,
        sfType: {
          '01': "居民身份证",
          5: "部队离退休证",
          9: "境外永久居住证",
          2: "军官证",
          6: "香港特区护照/身份证明",
          A: "护照",
          3: "士兵证",
          7: "澳门特区护照/身份证明",
          B: "户口薄",
          4: "文职干部证",
          8: "台湾居民来往大陆通行证",
          Z: "其他",
        },
        form: {
          uid: "",
          mm: "",
        },
        search: [],
        handleForm: {},

        isIndeterminate: false,
        checkList: [],
        userPhoto: {}
      };
    },
    methods: {
      getUserList() {
        userPhotoPage(this.pageData).then((res) => {
          console.log(res);
          if (res.status) {
            this.tabList = res.data;
            this.pageData.total = res.total;
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页
        this.getUserList() //渲染数据的函数
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getUserList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getUserList();
      },
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的照片数据, 是否继续?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            deleteUserPhoto(sid).then((res) => {
              if (res.status) {
                this.$message({
                  type: "success",
                  message: "数据删除成功!",
                });
              }
              this.getUserList();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
      handle() {
        handleUserPhone(this.handleForm.sfzjh, this.handleForm).then(res => {
          if (res.status) {
            this.$notify({
              title: "成功",
              message: "审核成功",
              type: "success",
            });
            this.$refs.imgHandle.hide()
            this.getUserList()
          }
        })
      },
      checkAll(e) {
        this.checkList = e ? this.tabList.map(item => {
          return item.sid
        }) : []
        this.isIndeterminate = e
      },
      handleBatch(s) {
        this.$confirm(`${s==1?'是否批量审核通过？':'是否批量审核不通过'}`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          userPhotoHandleBatch(this.checkList.join(","), s).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "审核成功!",
              });
              this.getUserList();
            }
          })
        }).catch(() => {
        });
      },
      handlePhoto(item) {
        this.userPhoto = item
        this.$refs.yzModalImg.show(item.sfzjhEnc, item.sid)
      }
    },
    mounted() {
      this.search = [{
          title: "系统审核状态",
          bound: ["bzzxthyzt"],
          type: "select",
          dict: [{
              name: "待审",
              value: "0"
            },
            {
              name: "审核通过",
              value: "1"
            },
            {
              name: "审核未通过",
              value: "2"
            },
          ]
        },
        {
          title: "人工审核状态",
          bound: ["bzzshzt"],
          type: "select",
          dict: [{
              name: "待审",
              value: "0"
            },
            {
              name: "审核通过",
              value: "1"
            },
            {
              name: "审核未通过",
              value: "2"
            },
          ]
        },
        {
          title: "姓名/身份证件号",
          bound: "keyword",
          type: "input",
        }
      ];
      this.getUserList();
    },
  };
</script>

<template>
  <Layout>
    <UserHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <yz-search :data="search" v-model="pageData" @seachList="searchClick"></yz-search>
                <button @click="handleBatch(1)" type="button" class="btn btn-info h30 flexList mr-2">批量审核通过</button>
                <button @click="handleBatch(2)" type="button" class="btn btn-danger h30 flexList mr-2">批量审核不通过</button>
              </div>
            </div>
            <el-checkbox-group v-model="checkList">
              <div class="table-responsive border mt-3">
                <table class="table light-table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 8%">
                        <el-checkbox :indeterminate="isIndeterminate" @change="checkAll"></el-checkbox>
                      </th>
                      <th style="width: 10%">姓名</th>
                      <th style="width: 15%">证件号码</th>
<!--                      <th style="width: 8%">证件类型</th>-->
                      <th style="width: 20%">原因</th>
<!--                      <th style="width: 10%">提交时间</th>-->
                      <!--                    <th style="width: 8%">证件审核状态</th>-->
                      <!--                    <th style="width: 6%">审核证件</th>-->
<!--                      <th style="width: 10%;">添加人</th>-->
                      <th style="width: 10%;">添加时间</th>
<!--                      <th style="width: 8%">照片状态</th>-->
                      <th style="width: 10%">审核人</th>
                      <th style="width: 10%">审核时间</th>
                      <th style="width: 10%">审核照片</th>
                      <th style="width: 5%">删除</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(obj, index) in tabList" :key="index">
                      <td scope="row">
                        <el-checkbox :label="obj.sid">{{ index + 1 +(pageData.pageNum-1)*pageData.pageSize }}</el-checkbox>
                      </td>
                      <td>{{ obj.xm }}</td>
                      <td>
                        <a href="javascript:;" @click="$yzUserInfo().show(obj.sfzjhEnc)"
                          class="blue-font">{{ obj.sfzjh }}</a>
                      </td>
<!--                      <td>{{ sfType[obj.sfzjlxm] }}</td>-->
                      <td :title="obj.bzzxthysm">
                        <span class="line1">{{ obj.bzzxthysm }}</span>
                      </td>
<!--                      <td>{{ timeSlice(obj.createTime) }}</td>-->
<!--                      <td>{{obj.modifyUser}}</td>-->
                      <td>{{timeSlice(obj.modifyTime)}}</td>
<!--                      <td>-->
<!--                        <HandleStatus :shzt="obj.bzzshzt?obj.bzzshzt:obj.bzzxthyzt" :prefix="obj.bzzshzt?'人工':'系统'">-->
<!--                        </HandleStatus>-->
<!--                      </td>-->
                      <td>{{ obj.bzzshrxm }}</td>
                      <td>{{ timeSlice(obj.bzzshrsj) }}</td>
                      <td title="点击审核">
                        <div @click="handlePhoto(obj)">

                          <HandleStatus :shzt="obj.bzzshzt?obj.bzzshzt:obj.bzzxthyzt" :prefix="obj.bzzshzt?'人工':'系统'">
                          </HandleStatus>
                        </div>
<!--                        <button type="button" @click="handlePhoto(obj)"-->
<!--                          :class="['btn','btn-'+((obj.xbzzshzt==0 && obj.xbzz)?'warning':'info'), 'h30','flexList mr-2']">-->
<!--                          审核-->
<!--                        </button>-->

                      </td>
                      <td class="">
                        <i
                            v-if="(obj.bzzshzt?obj.bzzshzt:obj.bzzxthyzt) != 1"
                            @click="deleteItem(obj.sid,obj.xm)"
                            class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-checkbox-group>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ImgHandle ref="imgHandle" :bound="['zjshzt', 'zjshbz']" v-model="handleForm" @change="handle"></ImgHandle>
    <yzModalSfzImg ref="yzModalImg" :img1="userPhoto.bzz" :img2="userPhoto.xbzz" title="审定系统标准照（白底）"
      :bound="['bzzshzt', 'bzzshsm']" v-model="handleForm" @change="handle" :sfzh="userPhoto.modifyZh " :zcyh="userPhoto.xm"></yzModalSfzImg>
  </Layout>
</template>
