<template>
<!--  不带审核状态的-->
  <div style="width:100%;">
    <div class="blue-font mt-2 mb-2">
      <i class="iconfont icon-ze-user-circle-o mr-2"></i>身份证照片
    </div>
    <div class="msgContent flexList fs-xs">
      <div class="per-img-box certificates50">
        <div class="per-img-top flexList">身份证扫描件（人像面）</div>
        <div class="per-img-photo certifi flexList justify-content-center">
          <img
            :src="
              userPhoto.sfzsmjrxm
                ? getFileUrl(userPhoto.sfzsmjrxm)
                : require('@/assets/images/person/card1.png')
            "
            alt="加载失败"
          />
        </div>
      </div>
      <div class="per-img-box certificates50">
        <div class="per-img-top flexList">身份证扫描件（国徽面）</div>
        <div class="per-img-photo certifi flexList justify-content-center">
          <img
            :src="
              userPhoto.sfzsmjghm
                ? getFileUrl(userPhoto.sfzsmjghm)
                : require('@/assets/images/person/card2.png')
            "
            alt="加载失败"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getStandardPhoto} from "@/api/userInfo_user.js";
export default {
  props: {
    sfzjh: String,
  },
  data() {
    return {
      userPhoto: {},
    };
  },
  mounted() {
    getStandardPhoto(this.sfzjh).then((res) => {
      if (res.status) {
        this.userPhoto = res.data || {};
        return;
      }
    });
  },
};
</script>

<style>
.certificates50 {
  width: 50% !important;
}
.per-img-photo{
  border-bottom: unset !important;
}
</style>
