<template>
  <b-modal
      v-model="showUser"
      centered
      title="身份证信息审核"
      size="xl"
      title-class="font-18"
      hide-footer
  >
    <base-info :sfzjh="sfzjh"></base-info>
    <image-info :sfzjh="sfzjh"></image-info>
    <div class="flexList check-distri mb-3 p-2">
      <label style="width: 10%">审核意见：</label>
      <div class="w-95 flexList">
        <textarea v-model="form[bound[1]]" id="" class="w-100 form-control" rows="3"></textarea>
      </div>
    </div>
    <div class="mt-3 text-center">
      <button
          type="button"
          class="btn btn-info h30 w-md mr-3"
          @click="handle(1)"
      >
        通过
      </button>
      <button
          type="button"
          class="btn btn-danger h30 w-md mr-3"
          @click="handle(2)"
      >
        不通过
      </button>
      <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showUser = false"
      >
        取消
      </button>
    </div>
  </b-modal>
</template>

<script>
import BaseInfo from "../base-info.vue";
import ImageInfo from "../img-info.vue";
export default {
  name: "user-show.vue",
  components: {
    BaseInfo,
    ImageInfo,
  },
  props:{
    title: String,
    bound: Array,
  },
  model:{
    event: "input",
    data: "form"
  },
  data() {
    return {
      showUser: false,
      sfzjh: "",
      form: {},
    };
  },
  methods: {
    handle(status){
      let yj = this.form[this.bound[1]]
      this.form = {}
      this.form[this.bound[0]] = status
      this.form[this.bound[1]] = yj
      this.form['sfzjh'] = this.sfzjh
      this.$emit("input", this.form)
      this.$emit("change", this.form)
    },
    show(sfzjh) {
      this.form = {}
      this.sfzjh = sfzjh;
      this.showUser = true;
    },
    hide(){
      this.showUser = false;
    }
  },
};
</script>

<style scoped></style>
