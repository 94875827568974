<!-- 管理员管理--带身份证的 -->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<script>
// import Multiselect from "vue-multiselect";
import {getCheckPhoto} from "@/api/userInfo_user";
import ImageInfoNone from "@/components/user/register_user/img-info-none";

/**
 * Dashboard component
 */
export default {
  props:{
    title: String,
    bound: Array,
    img1: String,
    img2: String,
    zcyh:String,
    sfzh:String

  },
  components: {
    // Multiselect
    ImageInfoNone
  },
  model:{
    event: "input",
    data: "form"
  },
  data() {
    return {
      showHandle: false,
      form: {},
      sfzjh: "",
      message: "",
      sid: "",
    };
  },
  methods: {
    handle(status){
      let yj = this.form[this.bound[1]]
      this.form = {}
      if (!yj){
        yj=(status==1)?'人工审核通过':'人工审核未通过'
      }
      this.form[this.bound[0]] = status
      this.form[this.bound[1]] = yj
      this.form.sfzjh = this.sfzjh
      this.$forceUpdate()
      this.$emit("input", this.form)
      this.$emit("change", this.form)
    },
    check(){
      getCheckPhoto(this.sid).then(res=>{
        if (res.status){
          this.message = res.message
        }
      })
    },
    show(sfzjh, sid){
      this.sfzjh = sfzjh
      this.sid = sid
      this.form = {}
      this.message = ""
      this.showHandle = true
    },
    hide(){
      this.showHandle = false
    }
  },
  mounted() {

  },
};
</script>

<template>
  <b-modal
        v-model="showHandle"
        centered
        :title="title"
        size="lg"
        title-class="font-18"
        hide-footer
    >
    <div>
      <div class="blue-font  mb-2"><i class="iconfont icon-ze-user-circle-o mr-2"></i>注册姓名：<span class="text-dark ml-1 mr-1">{{zcyh?zcyh:""}}</span>，身份证号：<span class="text-dark ml-1 mr-1">{{sfzh?sfzh:""}}</span> </div>
      <div>
        <div class="d-flex">
          <div class="per-img-box">
            <div class="per-img-top flexList">系统标准照（白底）</div>
            <div class="per-img-photo flexList justify-content-center">
              <img
                  :src="
                        img1
                          ? getFileUrl(img1)
                          : require('@/assets/images/person/person-none.png')
                      "
                  alt="加载失败"
              />
            </div>
            <div class="per-img-check p-2" v-if="!img1">
              未上传
            </div>
          </div>
          <div class="per-img-box" v-if="img2">
            <div class="per-img-top flexList">新系统标准照（白底）</div>
            <div class="per-img-photo flexList justify-content-center">
              <img
                  :src="
                        img2
                          ? getFileUrl(img2)
                          : require('@/assets/images/person/person-none.png')
                      "
                  alt="加载失败"
              />
            </div>
            <div class="per-img-check p-2" v-if="!img2">
              未上传
            </div>
          </div>
        </div>
      </div>

      <image-info-none :sfzjh="sfzjh" class="mb-3"></image-info-none>
      <div class="flexList check-distri mb-3" v-if="sid">
        <label class="mb-0" style="width: 13%">公安库对比：</label>
        <div class="col-sm-10 p-0 flexList">
          <span v-if="message">{{message}}</span>
          <span v-else>
            <a href="javascript:;" @click="check">点击对比</a>
          </span>
        </div>
      </div>
      <div class="flexList check-distri mb-3">
        <label class="mb-0" style="width: 13%">审核意见：</label>
        <div class="col-sm-10 p-0 flexList">
          <textarea v-model="form[bound[1]]" id="" class="form-control w-100" rows="5"></textarea>
        </div>
      </div>
    </div>
      <div class="mt-3 text-center">
        <button
            type="button"
            class="btn btn-info h30 w-md mr-3"
            @click="handle(1)"
        >
          通过
        </button>
        <button
            type="button"
            class="btn btn-danger h30 w-md mr-3"
            @click="handle(2)"
        >
          不通过
        </button>
        <button
            type="button"
            class="btn btn-secondary h30 w-md"
            @click="showHandle = false"
        >
          取消
        </button>
      </div>
    </b-modal>
</template>
